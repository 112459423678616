exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-404-js": () => import("./../../../src/pages/admin/404.js" /* webpackChunkName: "component---src-pages-admin-404-js" */),
  "component---src-pages-admin-blog-blog-details-js": () => import("./../../../src/pages/admin/blog/blog-details.js" /* webpackChunkName: "component---src-pages-admin-blog-blog-details-js" */),
  "component---src-pages-admin-blog-blog-list-js": () => import("./../../../src/pages/admin/blog/blog-list.js" /* webpackChunkName: "component---src-pages-admin-blog-blog-list-js" */),
  "component---src-pages-admin-blog-category-js": () => import("./../../../src/pages/admin/blog-category.js" /* webpackChunkName: "component---src-pages-admin-blog-category-js" */),
  "component---src-pages-admin-companies-js": () => import("./../../../src/pages/admin/companies.js" /* webpackChunkName: "component---src-pages-admin-companies-js" */),
  "component---src-pages-admin-company-user-js": () => import("./../../../src/pages/admin/company-user.js" /* webpackChunkName: "component---src-pages-admin-company-user-js" */),
  "component---src-pages-admin-contact-us-js": () => import("./../../../src/pages/admin/contact-us.js" /* webpackChunkName: "component---src-pages-admin-contact-us-js" */),
  "component---src-pages-admin-enquiries-js": () => import("./../../../src/pages/admin/enquiries.js" /* webpackChunkName: "component---src-pages-admin-enquiries-js" */),
  "component---src-pages-admin-forgot-password-js": () => import("./../../../src/pages/admin/forgot-password.js" /* webpackChunkName: "component---src-pages-admin-forgot-password-js" */),
  "component---src-pages-admin-forgot-qr-code-js": () => import("./../../../src/pages/admin/forgot-qr-code.js" /* webpackChunkName: "component---src-pages-admin-forgot-qr-code-js" */),
  "component---src-pages-admin-home-js": () => import("./../../../src/pages/admin/home.js" /* webpackChunkName: "component---src-pages-admin-home-js" */),
  "component---src-pages-admin-location-js": () => import("./../../../src/pages/admin/location.js" /* webpackChunkName: "component---src-pages-admin-location-js" */),
  "component---src-pages-admin-navbar-js": () => import("./../../../src/pages/admin/navbar.js" /* webpackChunkName: "component---src-pages-admin-navbar-js" */),
  "component---src-pages-admin-permission-js": () => import("./../../../src/pages/admin/permission.js" /* webpackChunkName: "component---src-pages-admin-permission-js" */),
  "component---src-pages-admin-registration-js": () => import("./../../../src/pages/admin/registration.js" /* webpackChunkName: "component---src-pages-admin-registration-js" */),
  "component---src-pages-admin-reset-password-js": () => import("./../../../src/pages/admin/reset-password.js" /* webpackChunkName: "component---src-pages-admin-reset-password-js" */),
  "component---src-pages-admin-reset-qrcode-js": () => import("./../../../src/pages/admin/reset-qrcode.js" /* webpackChunkName: "component---src-pages-admin-reset-qrcode-js" */),
  "component---src-pages-admin-restrict-email-js": () => import("./../../../src/pages/admin/restrict-email.js" /* webpackChunkName: "component---src-pages-admin-restrict-email-js" */),
  "component---src-pages-admin-roles-js": () => import("./../../../src/pages/admin/roles.js" /* webpackChunkName: "component---src-pages-admin-roles-js" */),
  "component---src-pages-admin-sidebar-js": () => import("./../../../src/pages/admin/sidebar.js" /* webpackChunkName: "component---src-pages-admin-sidebar-js" */),
  "component---src-pages-admin-signin-js": () => import("./../../../src/pages/admin/signin.js" /* webpackChunkName: "component---src-pages-admin-signin-js" */),
  "component---src-pages-admin-subscription-js": () => import("./../../../src/pages/admin/subscription.js" /* webpackChunkName: "component---src-pages-admin-subscription-js" */),
  "component---src-pages-admin-users-js": () => import("./../../../src/pages/admin/users.js" /* webpackChunkName: "component---src-pages-admin-users-js" */),
  "component---src-pages-admin-verify-js": () => import("./../../../src/pages/admin/verify.js" /* webpackChunkName: "component---src-pages-admin-verify-js" */),
  "component---src-pages-enroll-your-company-js": () => import("./../../../src/pages/enroll-your-company.js" /* webpackChunkName: "component---src-pages-enroll-your-company-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-forgot-qr-code-js": () => import("./../../../src/pages/forgot-qr-code.js" /* webpackChunkName: "component---src-pages-forgot-qr-code-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-30-m-secret-for-refinery-success-no-surprises-its-ai-js": () => import("./../../../src/pages/resources/30m-secret-for-refinery-success-no-surprises-its-ai.js" /* webpackChunkName: "component---src-pages-resources-30-m-secret-for-refinery-success-no-surprises-its-ai-js" */),
  "component---src-pages-resources-beyond-the-hype-ai-built-for-the-energy-industry-js": () => import("./../../../src/pages/resources/beyond-the-hype-ai-built-for-the-energy-industry.js" /* webpackChunkName: "component---src-pages-resources-beyond-the-hype-ai-built-for-the-energy-industry-js" */),
  "component---src-pages-resources-blog-js": () => import("./../../../src/pages/resources/blog.js" /* webpackChunkName: "component---src-pages-resources-blog-js" */),
  "component---src-pages-resources-bolo-ai-selected-for-aws-generative-ai-energy-lab-2024-js": () => import("./../../../src/pages/resources/bolo-ai-selected-for-aws-generative-ai-energy-lab-2024.js" /* webpackChunkName: "component---src-pages-resources-bolo-ai-selected-for-aws-generative-ai-energy-lab-2024-js" */),
  "component---src-pages-user-404-js": () => import("./../../../src/pages/user/404.js" /* webpackChunkName: "component---src-pages-user-404-js" */),
  "component---src-pages-user-chat-js": () => import("./../../../src/pages/user/chat.js" /* webpackChunkName: "component---src-pages-user-chat-js" */),
  "component---src-pages-user-company-users-js": () => import("./../../../src/pages/user/company-users.js" /* webpackChunkName: "component---src-pages-user-company-users-js" */),
  "component---src-pages-user-new-documents-js": () => import("./../../../src/pages/user/new/Documents.js" /* webpackChunkName: "component---src-pages-user-new-documents-js" */),
  "component---src-pages-user-new-dropdown-js": () => import("./../../../src/pages/user/new/Dropdown.js" /* webpackChunkName: "component---src-pages-user-new-dropdown-js" */),
  "component---src-pages-user-new-new-chat-js": () => import("./../../../src/pages/user/new/NewChat.js" /* webpackChunkName: "component---src-pages-user-new-new-chat-js" */),
  "component---src-pages-user-new-sidebar-js": () => import("./../../../src/pages/user/new/sidebar.js" /* webpackChunkName: "component---src-pages-user-new-sidebar-js" */),
  "component---src-pages-user-registration-js": () => import("./../../../src/pages/user/registration.js" /* webpackChunkName: "component---src-pages-user-registration-js" */),
  "component---src-pages-user-reset-password-js": () => import("./../../../src/pages/user/reset-password.js" /* webpackChunkName: "component---src-pages-user-reset-password-js" */),
  "component---src-pages-user-reset-qrcode-js": () => import("./../../../src/pages/user/reset-qrcode.js" /* webpackChunkName: "component---src-pages-user-reset-qrcode-js" */),
  "component---src-pages-user-users-js": () => import("./../../../src/pages/user/users.js" /* webpackChunkName: "component---src-pages-user-users-js" */),
  "component---src-pages-user-verify-js": () => import("./../../../src/pages/user/verify.js" /* webpackChunkName: "component---src-pages-user-verify-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

